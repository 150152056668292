<template>
  <static-fullscreen-card>
    <template v-slot:title>{{ data.name }} ({{ data.id }})</template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$router.push({ name: 'clients' })"
        >Перейти в Клиенты</v-btn
      >
      <v-btn
        color="primary"
        @click="showEditDialog = true"
        title="Редактировать элемент"
        ><btn-title icon="far fa-edit">Редактировать</btn-title></v-btn
      ></template
    >
    <portal to="v-main">
      <edit-dialog v-model="showEditDialog" :id="id"></edit-dialog>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>фото клиента</div>
          </template>
          <a-view-photo :value="data.photo" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading>
            <div>основная информация</div>
          </template>
          <a-form-view
            :value="data"
            :model="[
              model[0],
              model[1],
              model[2],
              model[3],
              model[4],
              model[5],
              model[6],
              model[7],
            ]"
          />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="warning">
          <template v-slot:heading>
            <v-tabs
              v-model="tab"
              background-color="transparent"
              slider-color="white"
            >
              <v-tab v-for="(tab, i) in tabs" :key="i" class="mr-3">{{
                tab.title
              }}</v-tab>
            </v-tabs>
          </template>
          <v-tabs-items
            v-model="tab"
            class="transparent"
            style="min-height: 200px"
          >
            <v-tab-item>
              <v-card-text style="height: 300px">
                <a-table-f-api
                  ref="table2"
                  api="/kazan/rooms"
                  :model="$store.getters['config/get'].models.rooms.list"
                  :searchable="false"
                  :defaults="{
                    filters: { 'sales.buyer_id': id },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="
                    $router.push({
                      name: 'rooms_view',
                      params: { id: $event.row.id },
                    })
                  "
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              ><v-card-text style="height: 300px">
                <a-table-f-api
                  ref="table1"
                  api="/kazan/objects"
                  :model="$store.getters['config/get'].models.objects.list"
                  :searchable="false"
                  :defaults="{
                    filters: { seller_id: id },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                  @click="
                    $router.push({
                      name: 'objects_view',
                      params: { id: $event.row.id },
                    })
                  "
                />
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              ><v-card-text style="height: 300px; overflow: auto">
                <a-view-files :value="data.files" />
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text style="height: 300px">
                <a-table-f-api
                  ref="table3"
                  api="/caller/calls"
                  :model="callModel"
                  :searchable="false"
                  :defaults="{
                    filters: { phone: [data.phone1, data.phone2] },
                    sort: { key: 'id', order: 'DESC' },
                  }"
                />
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    editDialog: () => import("./dialogs/clientDialog"),
  },
  data() {
    return {
      id: 0,
      showEditDialog: false,
      title: "",
      data: {},
      model: this.$store.getters["config/get"].models.clients.form,
      url: "/kazan/clients",
      tab: 0,
      tabs: [
        { title: "Студии" },
        { title: "Объекты" },
        { title: "Документы" },
        { title: "Разговоры" },
      ],
      callModel: [
        {
          name: "calldate",
          type: "datetime",
          title: "Время",
           width: 130,
        },
        {
          name: "status",
          type: "select",
          options: this.$store.getters["config/get"].enums.callStatuses,
          title: "Статус",
          sortable: true,
          width: 100,
        },
        {
          name: "destination",
          type: "select",
          options: this.$store.getters["config/get"].enums.destinations,
          title: "Направление",
          sortable: true,
          width: 100,
        },
        {
          name: "manager_name",
          type: "string",
          filter: {
            type: "selectapi",
            name: "users.id",
            api: "/manager/users/select",
        },
          title: "Менеджер",
          sortable: true,
          width: 150,
        },
        {
          name: "billsec",
          type: "string",
          title: "Время разговора",
          sortable: true,
          width: 120,
        },
        {
          name: "recordingfile",
          type: "record",
          title: "Запись разговора",
          sortable: false,
          width: 350,
        },
      ],
    };
  },
  created() {
    this.$root.title = "Клиенты";
    this.id = Number(this.$route.params.id);
    this.fitchData();
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  methods: {
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
    },
  },
};
</script>
