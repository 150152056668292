var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('static-fullscreen-card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.data.name)+" ("+_vm._s(_vm.data.id)+")")]},proxy:true},{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'clients' })}}},[_vm._v("Перейти в Клиенты")]),_c('v-btn',{attrs:{"color":"primary","title":"Редактировать элемент"},on:{"click":function($event){_vm.showEditDialog = true}}},[_c('btn-title',{attrs:{"icon":"far fa-edit"}},[_vm._v("Редактировать")])],1)]},proxy:true}])},[_c('portal',{attrs:{"to":"v-main"}},[_c('edit-dialog',{attrs:{"id":_vm.id},model:{value:(_vm.showEditDialog),callback:function ($$v) {_vm.showEditDialog=$$v},expression:"showEditDialog"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',[_vm._v("фото клиента")])]},proxy:true}])},[_c('a-view-photo',{attrs:{"value":_vm.data.photo}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('material-card',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',[_vm._v("основная информация")])]},proxy:true}])},[_c('a-form-view',{attrs:{"value":_vm.data,"model":[
            _vm.model[0],
            _vm.model[1],
            _vm.model[2],
            _vm.model[3],
            _vm.model[4],
            _vm.model[5],
            _vm.model[6],
            _vm.model[7] ]}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('material-card',{attrs:{"color":"warning"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('v-tabs',{attrs:{"background-color":"transparent","slider-color":"white"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(tab,i){return _c('v-tab',{key:i,staticClass:"mr-3"},[_vm._v(_vm._s(tab.title))])}),1)]},proxy:true}])},[_c('v-tabs-items',{staticClass:"transparent",staticStyle:{"min-height":"200px"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('a-table-f-api',{ref:"table2",attrs:{"api":"/kazan/rooms","model":_vm.$store.getters['config/get'].models.rooms.list,"searchable":false,"defaults":{
                  filters: { 'sales.buyer_id': _vm.id },
                  sort: { key: 'id', order: 'DESC' },
                }},on:{"click":function($event){return _vm.$router.push({
                    name: 'rooms_view',
                    params: { id: $event.row.id },
                  })}}})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('a-table-f-api',{ref:"table1",attrs:{"api":"/kazan/objects","model":_vm.$store.getters['config/get'].models.objects.list,"searchable":false,"defaults":{
                  filters: { seller_id: _vm.id },
                  sort: { key: 'id', order: 'DESC' },
                }},on:{"click":function($event){return _vm.$router.push({
                    name: 'objects_view',
                    params: { id: $event.row.id },
                  })}}})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px","overflow":"auto"}},[_c('a-view-files',{attrs:{"value":_vm.data.files}})],1)],1),_c('v-tab-item',[_c('v-card-text',{staticStyle:{"height":"300px"}},[_c('a-table-f-api',{ref:"table3",attrs:{"api":"/caller/calls","model":_vm.callModel,"searchable":false,"defaults":{
                  filters: { phone: [_vm.data.phone1, _vm.data.phone2] },
                  sort: { key: 'id', order: 'DESC' },
                }}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }